.footer {
  background-color: #1C1D1F;
  padding-top: 12rem;
  padding-bottom: 12rem;
  width: 100%;
  display: flex;
  align-items: center;

  &--container {
    width: 90%;
    max-width: 1500px;
    margin: auto;
  }

  &--content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .content--left {
    cursor: pointer;
    width: 400px;
    transition: all 0.3s;

    img {
      width: 100%;
    }

    &:hover {
      opacity: 80%;
    }
  }

  .content--right {
    display: flex;
  }

  .content--location,
  .content--contact,
  .content--follow {
    padding: 0 3rem 3rem 3rem;
  }

  .content--location {
    display: flex;
    flex-direction: column;
  }

  .location {
    color: var(--secondary-grey);
    font-size: 1.8rem;
  }

  .content--contact {
    display: flex;
    flex-direction: column;
  }

  .content--follow {
    display: flex;
    flex-direction: column;
  }

  .content--title {
    color: #FFF;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .content--link {
    font-size: 1.8rem;
    text-decoration: none;
    color: var(--secondary-grey);
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    transition: all 0.2s;

    svg {
      margin-right: 5px;
    }

    &:hover {
      color: #FFF;
    }
  }
}

@media (max-width: 1300px) {
  .footer--content {
    flex-direction: column !important;
    justify-content: initial;
  }

  .content--left {
    margin-bottom: 3rem;
  }

  .content--right {
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column !important;
  }

  .content--location, 
  .content--contact, 
  .content--follow {
    padding: 0 0 3rem 0 !important;
  }

  .content--link {
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .content--left {
    width: 300px !important;
  }
}

@media (max-width: 300px) {
  .content--left {
    width: 200px !important;
  }

  .content--location {
    padding: 0 1rem 1rem 1rem !important;

    p {
      font-size: 1.4rem !important;
    }
  }

  .content--contact {
    padding: 0 1rem 1rem 1rem !important;

    p, a {
      font-size: 1.4rem !important;
    }
  }

  .content--follow {
    padding: 0 1rem 1rem 1rem !important;

    p, a {
      font-size: 1.4rem !important;
    }
  }
}