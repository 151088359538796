.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 280px));
  grid-gap: 20px;
  justify-content: center;
  position: relative;

  &.animate {
    animation: mymove 2s;
  }

  .card {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    background-color: #FFF;
    height: 400px;
    box-shadow: 5px 5px 20px #d9d9d9;

    transition: all 0.3s ease 0s;

    &:hover {
      translate: 0 -1rem;
    }
  }

  .card__image {
    width: 100%;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card__label {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: yellow;
    font-size: 1.2rem;
    padding: 0 4px;
    font-weight: 600;
    color: #000;
    border: 1px solid #000;
  }

  .card__top {
    padding: 1rem;
    color: var(--primary-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card__price {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .card__year {
    background-color: #D9D9D9;
    padding: 1rem;
    border-radius: 8px;
    color: var(--secondary-grey);
    font-size: 1.4rem;
    font-weight: 600;
  }

  .card__bottom {
    font-size: 1.4rem;
    color: var(--primary-black);
  }

  .card__title {
    text-transform: uppercase;
    padding: 1rem;
  }

  .card__info {
    display: flex;
    padding-top: 2rem;
    flex-wrap: wrap;

    p {
      display: flex;
      align-items: center;
      padding: 0 1rem 1rem 1rem;
    }

    svg {
      margin-right: 5px;
    }
  }

  .card--button {
    border: none;
    background-color: var(--secondary-green);
    height: 3rem;
    width: 9rem;
    border-radius: 10px;
    color: #FFF;
    font-size: 1.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px
    }
  }
}

@keyframes mymove {
  from {
    top: 100px;
  }

  to {
    top: 0px;
  }
}