.carousel {

  margin-top: 100px;

  &--button {
    position: absolute;
    width: 100%;
    height: 90vh;
  }

  .arrow__prev {
    position: absolute;
    z-index: 2;
    top: 47%;
    margin-left: 2rem;
    border: none;
    height: 4rem;
    width: 4rem;
    background-color: #fff;
    opacity: 50%;
    cursor: pointer;

    &:hover {
      opacity: 100%;
      transition: 0.25s ease-in-out;
    }
  }

  .arrow__next {
    position: absolute;
    z-index: 2;
    top: 47%;
    right: 0;
    margin-right: 2rem;
    border: none;
    height: 4rem;
    width: 4rem;
    background-color: #fff;
    opacity: 50%;
    cursor: pointer;

    &:hover {
      opacity: 100%;
      transition: 0.25s ease-in-out;
    }
  }

  .arrow--icon {
    font-size: 2rem;
  }

  &--slider {
    overflow: hidden;
  }

  &--one {
    background-image: url(../../assets/images/carousel/1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 90vh;      
  }

  &--two {
    background-image: url(../../assets/images/carousel/2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 90vh;      
  }

  &--three {
    background-image: url(../../assets/images/carousel/3.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 90vh;      
  }

  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    width: 90%;
    margin: auto;
  }

  &--information {
    width: 40%;
  }

  &--title {
    text-align: center;
    color: #FFF;
    font-weight: bold;
    font-size: 6rem;   
    line-height: 1;
    margin-bottom: 1rem; 
    text-shadow: 2px 2px 5px #000;
  }

  &--text {
    line-height: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #FFF;
    font-size: 2rem;
    font-weight: 300;
  }
  
}

@media (max-width: 1150px) {
  .carousel--information {
    width: 50%;
  }
  
  .carousel--title {
    font-size: 4rem;
  }
}

@media (max-width: 600px) {
  .carousel--title {
    font-size: 2rem;
  }
}