/* ============ BACKGROUND ============ */ 
.fundo {

    background-image: url("./bg-veiculos-cia.png");    
    background-position: top center;    
    background-repeat: no-repeat;
    background-color: #000000;
 }   
     
 /* ============ BACKGROUND ============ */ 
 .bg-card {
 
    background-repeat: no-repeat;
    width: 100%; height: 978px;
 }       
     
 /* ============ CENTRALIZA DIV ============ */      
 .centered {
     margin: 0 auto !important;
     float: none !important;
 }  
     
     
 /* ============ LINKS ============ */
 
 .Menus {
     font-family: 'Roboto', sans-serif;
     text-transform:uppercase; text-decoration: none; line-height: 28px;
     color:#000000; font-size:17px; text-align:center; 
 }
 .Menus:hover {
     color:#454545; text-decoration: none;
 }  
     
 /* ============ TEXTO ============ */ 
 
 .Texto-Instagram {
     font-family: 'Karla', sans-serif;    
     color:#FDF8D0; font-size:20px; text-align:center; 
 }    