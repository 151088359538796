.modal--ofertas, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 10;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal--content {
    animation: fadeIn 2s;
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 28px 28px;
    border-radius: 3px;
    border: 1px solid #dee2e6;
    max-width: 600px;
    min-width: 350px;
    width: 100%;
}

.close--modal {
    font-size: 20px;
    font-weight: 400;
    color: #777777;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 2px;
    padding-right: 2px;

    &:hover {
        color: #000;
    }
}