@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Bebas+Neue&family=Montserrat:wght@400;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;700&display=swap');
@import '~bootstrap/scss/bootstrap';

:root {
  --background: #f6f6f6;  
  --primary-green: #266d00;
  --secondary-green: #569962;

  --btn-green: #028907;
  --btn-green-hover: #046f00;

  --primary-black: #2d2d2d;
  --secondary-black: #979797;
  
  --primary-grey: #495057;
  --secondary-grey: #777777;
  --orange: #FF9F29;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;  
  outline: 0;
}

html {
  font-size: 62.5% !important;
}

body {
  background-color: var(--background) !important;
  font-size: 1.6rem !important;
  color: var(--primary-black);
}

html,
body {
  height: 100vh;
  font-family: 'Roboto', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0 !important;
}

h1, h2, h3, h4, h5, h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0 !important;
  font-weight: unset;
  line-height: unset;
}

a {
  border: none !important;
}

p {
  margin-bottom: 0;
}