.financiamento {
    margin-top: 100px;

    &__pic {
        position: relative;
        width: 100%;
        height: 600px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        h1 {
            color: #FFF;
            font-size: 4rem;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }

    &__container {
        max-width: 1500px;
        margin: auto;
        width: 90%;
    }

    &__content {
        h1 {
            font-size: 3rem;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        p {
            font-size: 1.6rem;
            margin-bottom: 20px;
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        margin-top: 6rem;
        margin-bottom: 4rem;

        h1 {
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 1rem !important;
            text-align: center !important;;
        }

        a {
            display: flex;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            border: none;
            background-color: #FF9F29;
            padding: 10px 22px;
            text-decoration: none;
            color: var(--primary-black);
            font-size: 1.8rem;
            transition: all ease-in-out 0.2s;

            svg {
                margin-right: 5px;
            }

            &:hover {
                background-color: var(--primary-black);
                color: #FF9F29;
            }
        }
    }
}