.notfound {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/NotFound/notfoundbg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &__container {
        width: 90%;
        height: 100%;
        max-width: 1500px;
        margin: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            color: #FFF;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            border: none;
            background-color: #FFF;
            height: 60px;
            padding: 0 20px 0 20px;
            font-size: 1.8rem;
            cursor: pointer;
            color: var(--primary-green);
            transition: all ease-in-out 0.2s;

            svg {
                margin-right: 5px;
            }

            &:hover {
                opacity: 90%;
            }
        }
    }
}

@media (max-width: 600px) {
    .notfound__container {
        img {
            width: 300px;
        }
    }
}

@media (max-width: 350px) {
    .notfound__container {
        img {
            width: 250px;
        }
    }
}