.contato {
    &__banner {
        padding-top: 100px;

        h1 {
            font-size: 4rem;
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    &__container {
        width: 90%;
        max-width: 1500px;
        margin: auto;
    }

    &__cards {
        display: flex;
        justify-content: center;
        padding-top: 4rem;
        gap: 15px;
        flex-wrap: wrap;
    }

    &__card {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
        background-color: #FFF;
        height: 200px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        margin-bottom: 4rem;
    }

    .card__single {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: center;

        p {
            color: var(--secondary-black);
            font-weight: 600;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 1.6rem;
            font-weight: 600;
        }
    }

    .contato__icon {
        background-color: var(--primary-green);
        color: #FFF;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    &__content {
        background-color: #FFF;

        padding-top: 4rem;
        padding-bottom: 4rem;

        h1 {
            text-align: center;
            font-size: 4rem;
            margin-bottom: 3rem !important;
            font-weight: 600;
        }
    }

    &__left {
        width: 60%;
        height: 600px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__right {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
    }

    .right__content {
        position: absolute;
        width: 100%;
        right: 40px;
        background-color: #FFF;
        display: flex;
        flex-direction: column;

        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .input__group {
        margin-bottom: 2rem;
        height: 60px;
        position: relative;
    }

    .input__label {
        font-size: 1.4rem;
        color: var(--secondary-grey);
        position: absolute;
        top: 0;
    }

    .input__field {
        position: absolute;
        bottom: 0;

        font-size: 1.6rem;
        font-family: "Roboto", sans-serif;
        padding: 10px 0 10px 0;
        border: none;
        width: 100%;
        border-bottom: 1px solid var(--secondary-grey);
        transition: border-bottom 0.2s linear;

        &:focus {
            border-bottom: 2px solid var(--primary-green);
        }

        &:hover {
            border-bottom: 2px solid var(--primary-green);
        }
    }

    .input__field:focus+.input__label {
        color: var(--primary-green);
    }

    &__btn {
        background-color: #E22937;
        border: none;
        cursor: pointer;
        width: 100px;
        font-size: 1.6rem;
        color: #FFF;
        font-family: "Roboto", sans-serif;
        height: 50px;
        transition: all 0.3s;

        &:hover {
            opacity: 80%;
        }
    }
}

@media (max-width: 800px) {
    .contato__left {
        width: 40%;
    }

    .contato__right {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .contato__banner h1 {
        font-size: 2rem;
    }
    
    .contato__content h1 {
        font-size: 2rem;
    }
}