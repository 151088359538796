.modal {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .333);
    
    .modal__filter {
        position: absolute;
        left: -250px;
        
        width: 250px;
        background: #FFF;
        height: 100%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        transition: all 0.2s ease;
    }

    .modal__filter.move {
        left: 250px;
        transform: translate(-250px, 0);
    }

    .modal__top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-weight: 700;
        border-bottom: 1px solid #CCC;
        font-size: 16px;
        padding: 20px;

        button {
            display: flex;
            border: none;
            background: transparent;

            svg {
                cursor: pointer;
                font-size: 16px;
            }
        }

        
    }

    .filter__input {
        width: 250px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-bottom: 1px solid #CCC;

        label {
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 5px;
        }
    }

    .ano__input {
        display: flex;

        input {
            color: var(--secondary-grey);
            font-size: 14px;
            margin-right: 10px;
            width: 100%;
            padding: 5px;
            border-radius: 5px;
            border: 2px solid #CCC;
        }

        input:focus {
            border-color: var(--primary-black);
        }

        input:nth-last-child(1) {
            margin-right: 0;
        }
    }

    .input__field {
        padding: 5px;
        border-radius: 5px;
        border: 2px solid #CCC;
        font-size: 14px;
        color: var(--secondary-grey);
    }

    .input__field:focus {
        border-color: var(--primary-black);
    }

    .filter__button {
        padding: 10px;

        button {
            cursor: pointer;
            width: 100%;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            background-color: #FFF;
            border: 1px solid var(--secondary-grey);
            border-radius: 5px;
            height: 40px;
            padding: 0 14px;
            color: var(--secondary-grey);
            transition: all ease-in-out 0.2s;
        }

        button:hover {
            background-color: #f6f6f6;
        }
    }
}


.hidden__button {
    display: none;
    width: 100px;
    cursor: pointer;    
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    background-color: #FFF;
    border: 1px solid var(--secondary-grey);
    border-radius: 5px;
    height: 40px;
    padding: 0 14px;
    color: var(--secondary-grey);
    transition: all ease-in-out 0.2s;

    &:hover {
        background-color: #f6f6f6;
    }
}

.estoque {
    &__container {
        display: flex;
        width: 90%;
        max-width: 1500px;
        margin: auto;
        grid-gap: 2rem;

        padding-top: 150px;
        padding-bottom: 50px;
    }

    .filter {
        height: 355px;
        border: 1px solid #CCC;
        background-color: #FFF;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }

    .filter__title {
        font-weight: 700;
        border-bottom: 1px solid #CCC;
        font-size: 20px;
        padding: 10px;
    }

    .filter__input {
        width: 250px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-bottom: 1px solid #CCC;

        label {
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 5px;
        }
    }

    .ano__input {
        display: flex;

        input {
            color: var(--secondary-grey);
            font-size: 14px;
            margin-right: 10px;
            width: 100%;
            padding: 5px;
            border-radius: 5px;
            border: 2px solid #CCC;
        }

        input:focus {
            border-color: var(--primary-black);
        }

        input:nth-last-child(1) {
            margin-right: 0;
        }
    }

    .input__field {
        padding: 5px;
        border-radius: 5px;
        border: 2px solid #CCC;
        font-size: 14px;
        color: var(--secondary-grey);
    }

    .input__field:focus {
        border-color: var(--primary-black);
    }

    .filter__button {
        padding: 10px;

        button {
            cursor: pointer;
            width: 100%;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            background-color: #FFF;
            border: 1px solid var(--secondary-grey);
            border-radius: 5px;
            height: 40px;
            padding: 0 14px;
            color: var(--secondary-grey);
            transition: all ease-in-out 0.2s;
        }

        button:hover {
            background-color: #f6f6f6;
        }
    }
}

@media (max-width: 830px) {
    .estoque__container {
        flex-direction: column;
        grid-gap: 2rem;
    }
    
    .filter {
        display: none;
    }

    .hidden__button {
        display: block;
    }
}