.areaUsuario {
    position: relative;
    left: 250px;
    width: calc(100% - 250px);
    padding-top: 8px;
    padding-bottom: 8px;
    
    &__container {
        width: 90%;
        max-width: 1500px;
        margin: auto;
    }

    &__left {
        display: block;
    }

    .box {
        padding: 1.5rem;
        background-color: #FFF;
        border-radius: 20px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .box.anuncios {
        border-radius: 0 0 8px 8px !important;
    }

    &__btn {
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        background-color: #FFF;
        padding: 0 10px 0 10px;
        cursor: pointer;
        border: 2px solid var(--secondary-black);
        border-radius: 8px;
        font-size: 1.4rem;
        transition: all ease-in-out 0.3s;
        text-decoration: none;
        color: var(--primary-black);
        font-weight: 600;
        margin-bottom: 1rem;

        svg {            
            margin-right: 8px;
        }

        &:hover {
            background-color: #e6e6e6;
        }
    }

    &__btn.logout {
        color: #d32f2f;
        border: 2px solid rgba(211, 47, 47, 0.5);

        &:hover {
            -webkit-text-decoration: none;
            text-decoration: none;
            background-color: rgba(211, 47, 47, 0.04);
            border: 2px solid #d32f2f;
        }
    }

    &__btn:nth-last-child(1) {
        margin-bottom: 0;
    }

    &__right {

    }

    .cardAdmin__top {                
        padding: 3rem;
        background-color: var(--primary-green);
        border-radius: 8px 8px 0 0;
    }

    .cardAdmin__title {
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 700;
        color: #FFF;

        span {
            display: flex;
            padding-right: 5px;
        }
    }

    .titulo__border {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--secondary-green);
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .cardAdmin__container {
        display: flex;
        position: relative;         
    }

    .cardAdmin__left {
        width: 190px;
        height: 175px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;            
        }
    }

    .cardAdmin__right {
        margin-left: 1rem;
    }

    .cardAdmin__view {
        position: absolute;
        right: 0;
    }

    .cardAdmin__data {
        color: var(--secondary-black);
        font-size: 1.2rem;
    }

    .cardAdmin__titulo {
        font-size: 2rem;
        font-weight: 400;
        margin-top: 5px;
        text-transform: uppercase;
    }

    .cardAdmin__valor {
        margin-top: 5px;
        font-size: 3rem;
        font-weight: 600 !important;
    }

    .cardAdmin__info {
        display: flex;
        flex-wrap: wrap;
    }

    .single__info {
        display: flex;
        align-items: center;
        margin: 1rem 1rem 1rem 0;

        svg {
            margin-right: 5px;
        }
    }

    .single__info:nth-last-child(1) {
        margin-right: 0 !important;
    }

    .cardAdmin__btngroup {
        display: flex;
    }

    .cardAdmin__btn {
        display: flex;
        align-items: center;
        height: 35px;
        background-color: #FFF;
        padding: 0 10px 0 10px;
        margin-right: 1rem;
        cursor: pointer;
        border: 1px solid var(--secondary-black);
        border-radius: 8px;
        font-size: 1.4rem;
        transition: all ease-in-out 0.3s;
        text-decoration: none;
        color: var(--primary-black);

        svg {            
            margin-right: 5px;
        }

        &:hover {
            background-color: #e6e6e6;
        }
    }

    .cardAdmin__btn.danger {
        background-color: #dc3545;
        border-color: #dc3545;
        color: #FFF;

        &:hover {
            background-color: #c82333;
            border-color: #bd2130;
        }
    }

    .cardAdmin__btn.sale {
        cursor: initial;
    }

    .cardAdmin__btn:nth-last-child(1) {
        margin-right: 0;
    }

    .switch {
        width: 40px;
        height: 22px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        margin-left: 5px;
      }
      
      .switch .slider {
        position: absolute;
        inset: 0;
        background: #ccc;
        border-radius: 20px;
        transition: all 400ms ease;
      }
      
      .switch input {
        display: none;
      }
      
      .switch .slider::before {
        content: "";
        position: absolute;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        left: 3px;
        bottom: 2px;
        background: #fff;
        transition: all 400ms ease;
      }
      
      .switch input:checked + .slider {
        background: #34c759;
      }
      
      .switch input:checked + .slider::before {
        transform: translateX(17px);
      }

    .cardAdmin__border {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e6e6e6;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

}

@media(max-width: 870px) {
    .areaUsuario {
        width: calc(100% - 85px);
        left: 85px;
    }
    
    .cardAdmin__container {
        flex-direction: column;
    }

    .cardAdmin__right {
        margin-left: 0 !important;
    }

    .cardAdmin__view {
        padding: 1rem;
        background-color: #FFF;
        border-radius: 10px;
        font-size: 1.2rem;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    .cardAdmin__titulo {
        font-size: 1.6rem !important;
    }

    .cardAdmin__valor {
        font-size: 2.2rem !important;
    }
}

@media(max-width: 500px) {
    .cardAdmin__btn {
        svg {
            margin-right: 0 !important;
        }

        .span__txt {
            display: none;
        }
    }
}