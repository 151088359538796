.header {  
  background-color: #FFF;
  animation: fadeIn 3s;
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

  &--container {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1500px;
    margin: auto;
  }

  &--logo {
    cursor: pointer;
  }

  &--logo:hover {
    opacity: 80%;
    transition: 0.3s ease-out;
  }

  &--items {    
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--list {
    list-style: none;
    display: flex;
    flex-direction: row;

    .list--item {
      padding: 2rem;
    }

    .list--item:nth-child(1) {
      padding-left: 0;
    }

    .list--item:nth-last-child(1) {
      padding-right: 0;
    }

    .list--link {
      position: relative;
      text-decoration: none;
      color: var(--primary-black);
      font-weight: bold;
      padding-bottom: 1rem;
      cursor: pointer; 
      
      &:hover {
        color: var(--primary-green);
      }
    }

    .list--link::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      background-color: var(--primary-green);
      transition: width 0.25s ease-out;
    }

    .list--link:hover::before {
      width: 100%;
    }
  }

  
  
  .contact--link {
    font-weight: bold;
    text-decoration: none;
    color: var(--primary-black);
    cursor: pointer; 
    
    display: flex;
    align-items: center;
    justify-content: center;                 
  }
  
  .contact--icon{
    margin-right: 5px;
  }

  .contact--link:hover .contact--icon {
    color: #57ba63;      
    transition: 0.3s ease-in-out; 
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media (max-width: 1100px) {  
  .header--items {
    display: none;
  }

  .list--item {
    padding: 2rem !important;
  }

  .one,
  .two,
  .three {
    background-color: var(--primary-green);
    height: 5px;
    width: 100%;
    margin: 6px auto;
    border-radius: 8px;

    transition-duration: 0.3s;
  }

  .menu--toggle {
    width: 40px;
    height: 30px;    
  }

  .menu--section.on {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: var(--background);

    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu--section.on .header--items {
    display: block;
  }

  .menu--section.on .header--list {
    flex-direction: column;
    text-align: center;
    font-size: 2rem;
  }

  .menu--section.on .menu--toggle {
    position: absolute;
    right: 25px;
    top: 35px;
  }

  .menu--section.on .menu--toggle .one {
    transform: rotate(45deg) translate(7px, 7px);
  }

  .menu--section.on .menu--toggle .two {
    opacity: 0;
  }

  .menu--section.on .menu--toggle .three {
    transform: rotate(-45deg) translate(8px, -9px);
  }
  
}

@media (max-width: 500px) {
  .header--logo {
    width: 200px;
    height: 100%;
  }
}