.login.load {
    cursor: wait !important;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &__container {
        width: 90%;
        max-width: 330px;        
        background-color: #FFF;
        padding: 5rem 2rem;
        border-radius: 8px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 2rem !important;
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__group {
        position: relative;   
        margin-bottom: 2rem;     
    }

    .form__field {
        width: 100%;
        background: transparent;
        color: var(--secondary-black);        
        padding: 1rem;
        border: 2px solid var(--secondary-black);
        border-radius: 8px;      
        font-size: 1.8rem;
        margin-bottom: 1rem;         
    }

    .form__label {
        background-color: #fff;
        color: var(--secondary-black);
        position: absolute;
        left: 10px;
        top: -5px;
        padding: 0 6px;
        font-size: 1.2rem;
    }

    .form__error {
        margin-top: 2rem;
        color: var(--secondary-black);
    }

    .button {
        background-color: var(--primary-black);
        color: #FFF;
        height: 50px;
        padding: 0 2rem;
        cursor: pointer;
        border: none;
        font-weight: 700;
        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: #FFF;
            color: var(--primary-black);
            border: 1px solid var(--primary-black);
        }
    }    
}