.vehicle {
    padding-top: 100px;    

    &__container {
        width: 90%;
        max-width: 1500px;
        background-color: #FFF;
        margin: auto;
        border-radius: 0 0 8px 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    &__content {
        display: flex;
    }

    &__left {
        width: 60%; 
        position: relative;       
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        display: none !important;
    }        

    .arrow--prev, .arrow--next {
        position: absolute;
        top: 260px;
        z-index: 8;
        cursor: pointer;
        background-color: var(--primary-black);
        border: 0;
        padding: 16px 8px;     
        transition: all ease-in-out 0.2s;   

        svg {
            font-size: 26px;
            color: #FFF;
        }

        &:hover {
            opacity: 90%;
        }
    }

    .arrow--prev {
        border-radius: 0 8px 8px 0;
    }

    .arrow--next {
        border-radius: 8px 0 0 8px;        
        right: 0;
    }

    &__image {             
        width: 100%;        
        background-color: #f5f6f7;

        img {
            width: 100%;
            height: 600px;   
            object-fit: contain;         
        }
    }

    .slick-dots {
        position: relative;
        bottom: 5px;

        li {
            margin: 5px !important;
        }
    }

    .slick-dots.slick-thumb {        
        padding: 5px 1px 5px 1px !important;
        background-color: #FFF;

        li {
            width: 72px !important;
            height: 54px !important;

            a {                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__right {
        width: 40%;
    }

    .border__details {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: var(--secondary-grey);    
    }

    .right__content {
        padding: 20px;

        h1 {
            font-size: 4rem;
            font-weight: 600;
        }

        h2 {
            font-size: 2.6rem;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 34px;
        }

        table {
            display: block;
            color: var(--primary-grey);
            border-collapse: collapse !important;
        }
        
        tbody {
            display: block;
        }

        tr {
            display: block;
            border-bottom: 1px solid var(--secondary-grey);
        }

        .detail__title {
            width: 200px;       
        }
        
        th, td {
            padding: 10px 0 10px 0;
        }

        td:nth-child(even), th:nth-child(even) {
            font-weight: 600;            
        }

        

        .btn__group {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            padding: 0 10px 0 10px;
            font-size: 1.6rem !important;
            font-weight: 500;
            font-family: "Roboto", sans-serif;
            width: 49%;
            border-radius: 8px;

            svg {
                margin-right: 5px;
            }
        }

        .btn.telefone {
            background-color: #E8F1D9;
            color: #224D00;
            cursor: inherit;
            border: none !important;

            &:active {
                border: none !important;
            }
        }

        .btn.whatsapp {
            background-color: #63A105;
            color: #FFF;
            border: none;
            cursor: pointer;
            transition: all ease-in-out 0.2s;
            text-decoration: none;

            &:hover {
                opacity: 90%;
            }
        }
    }

    .info__title {
        font-weight: 600;
        color: var(--primary-grey);
        margin-top: 2rem;
        font-size: 1.8rem;
    }

    .vehicle__bottom {
        padding: 20px;
    }

    .observacao {
        margin-top: 1rem;
        color: var(--primary-grey);
    }    

    .relacionados {
        margin-top: 3rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: #FFF;

        &__container {
            width: 90%;
            max-width: 1300px;
            margin: auto;
        }

        &__content {
            margin-bottom: 2rem;
        }

        &__title {
            font-weight: 500;
            color: var(--primary-grey);
            font-size: 1.8rem;
        }

        &__link {
            text-decoration: none;
            color: #0067A0;
            transition: all ease-in-out 0.2s;

            &:hover {
                opacity: 90%;
            }
        }
    }
}

@media (max-width: 1100px) {
    .vehicle__content {
        flex-direction: column !important;
    }

    .vehicle__left {
        width: 100%;
    }

    

    .vehicle__right {
        width: 100%;
    }

    .right__content {
        tbody {
            border-bottom: 1px solid var(--secondary-grey) !important;
            margin-top: 2rem;
        }

        tr {
            display: inline-block !important;
            flex-wrap: wrap;
            flex-direction: column !important;
            border-bottom: none !important;
            margin-bottom: 2rem;
        }

        td {
            display: block !important;
            padding: 0 !important;
        }

        .detail__title {
            font-size: 1.3rem;
            padding-bottom: 5px !important;
        }
    }

    .relacionados__container {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .relacionados__content {
        margin-bottom: 2rem;
    }
}

@media (max-width: 425px) {
    .btn__group {
        flex-direction: column !important;
    }

    .btn.telefone,
    .btn.whatsapp {
        width: 100% !important;
    }

    .btn.whatsapp {
        margin-top: 1rem;
    }
}