.sale {
  text-decoration: none;

  &--pic {
    display: block;
    position: relative;
    transform: translateY(2px);
  }

  &--pic:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #171717);
    transform: translateY(1px);
  }

  &--img {
    height: 40vh;
    object-fit: cover;
    width: 100%;
  }

  &--content {
    font-family: 'Poppins', sans-serif;
    background-color: #171717;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  &--up {
    transform: rotate(-3deg);
    margin-bottom: 1.4rem;
  }

  &--title {
    font-weight: bolder;
    font-style: italic;
    font-size: 4rem;
    line-height: 1;
    text-shadow: 1px 1px var(--primary-green);
    color: #171717;
  }

  &--title.mid {
    color: #FFF;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1),
      2px 2px 0px rgba(102, 102, 102, 1);
  }

  &--info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
  }

  &--model {
    font-weight: bold;
  }

  &--desc {
    color: #171717;
    font-weight: bold;
    background-color: #FFF;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &--price {
    display: flex;
    align-items: flex-start;
    color: var(--primary-green);
    font-weight: bold;
    margin-bottom: 3rem;
  }

  &--rs,
  &--cents {
    font-size: 2.4rem;
  }

  &--value {
    font-size: 5rem;
    line-height: 1;
  }

  &--bottom {}

  &--social {
    display: flex;
    font-size: 3rem;
  }

  &--contact {
    line-height: 1.1;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    margin-left: 5px;
  }

  @media (max-width: 500px) {
    &--title {
      font-size: 3rem;
    }

    &--model,
    &--desc {
      text-align: center;
      line-height: 1.2;
    }
  }
}

.glider-next, .glider-prev {
  top: 50% !important;
}

.glider-dot.active {
  background: var(--secondary-green) !important;
}

.home {
  background-color: #FFF;
  padding-top: 4rem;

  &--container {
    width: 90%;
    max-width: 1500px;
    margin: auto;
  }

  &--models {
    animation: fadeIn 4s;
  }

  .models--title {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
  }

  .models--line {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .line.first {
    content: "";
    border: 1px solid #D9D9D9;
    width: 10rem;
    display: inline-block;
  }

  .line.second {
    content: "";
    border: 1px solid var(--primary-green);
    width: 10rem;
    display: inline-block;
  }

  .line.third {
    content: "";
    border: 1px solid #D9D9D9;
    width: 10rem;
    display: inline-block;
  }

  .models--button {
    cursor: pointer;
    border: none;
    background-color: #FF9F29;
    height: 5rem;
    width: 27rem;
    text-decoration: none;
    color: #FFF;
    font-size: 1.8rem;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    transition: all 0.2s;

    svg {
      margin-left: 1rem;
      font-size: 2rem;
    }

    &:hover {
      background-color: #fd910d;
    }
  }

  .recomendados__container {
    width: 90%;
    max-width: 1500px;
    margin: auto;
  }

  &--filter {
    // width: 90%;
    // max-width: 1300px;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 10rem;
  }

  .filter--title {
    // margin-left: 1rem;
    margin-bottom: 2rem;
    font-size: 3rem;
    font-weight: bold;
  }

  .brand--title {
    color: var(--primary-green);
  }

  &--brands {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .brand--link {
    margin-bottom: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 160px;
    height: 80px;
    transition: all 0.2s;

    &:hover {
      opacity: 80%;
    }
  }

  .brand--link img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--financiamento {
    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 1rem !important;
    }

    p {
      font-size: 1.8rem;
    }

    a {
      text-decoration: none;
      color: var(--orange);

      &:hover {
        color: #fd910d;
      }
    }
  }

  &--about {
    background-image: url(../../assets/images/bg-aboutus.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .about--content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
  }

  .about--info {
    width: 50%;
  }

  .about--title {
    font-size: 5rem;
    font-weight: bold;
    color: #FFF;
    margin-bottom: 2rem;
  }

  .about--description {
    color: #FFF;
    font-size: 1.8rem;
    text-align: justify;
  }

  .about--images {
    position: relative;
    width: 50%;

    img {
      width: 100%;
    }
  }

  .circle--shape {
    background-color: var(--orange);
    width: 15rem;
    height: 15rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 50px;

    p {
      text-align: center;
      color: #FFF;
      font-weight: bold;
      text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
      padding: 2rem;
      font-size: 2rem;
    }
  }

  .instagramWrapper {
    position: relative;
    padding-bottom: 80%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .instagramWrapper object-fit,
  .instagramWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@media (max-width: 1370px) {
  .instagramWrapper {
    padding-bottom: 85% !important;
  }
}

@media (max-width: 1180px) {
  .about--content {
    flex-direction: column !important;
  }

  .about--images {
    width: 70% !important;
    order: -1;
  }

  .about--info {
    width: 70% !important;
    text-align: center;
  }

  .about--description {
    text-align: center !important;
  }

  .circle--shape {
    right: 0px !important;
  }

  .instagramWrapper {
    padding-bottom: 90% !important;
  }
}

@media (max-width: 780px) {
  .instagramWrapper {
    padding-bottom: 100% !important;
  }
}

@media (max-width: 590px) {
  .models--title {
    text-align: center;
  }

  .instagramWrapper {
    padding-bottom: 110% !important;
  }
}

@media (max-width: 570px) {
  .circle--shape {
    width: 10rem !important;
    height: 10rem !important;

    p {
      font-size: 1.4rem !important;
    }
  }
}

@media (max-width: 466px) {
  .filter--title {
    text-align: center;
    margin-bottom: 3rem;
  }

  .home--brands {
    justify-content: center;
  }

  .home--financiamento {
    text-align: center;
  }

  .instagramWrapper {
    padding-bottom: 115% !important;
  }
}