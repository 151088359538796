.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #FFF;
    padding: 10px 14px;

    .image__text {
        display: flex;
        align-items: center;
    }

    .image__text img {
        width: 40px;
        border-radius: 6px;
    }

    .header__text {
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--secondary-black);
    }

    .grant {
        font-weight: 400;
        margin-top: -2px;
    }

    .sidebar__image {
        min-width: 60px;
        display: flex;
        align-items: center;
    }

    .menu__bar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 50px);
    }

    .menu__links {
        display: block;
    }

    .nav__link {
        display: flex;
        width: 100%;
        align-items: center;
        height: 50px;
        margin-top: 10px;
    }

    .nav__link a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: var(--secondary-black);
        font-weight: 600;
        transition: all ease-in-out 0.3s;

        svg {
            font-size: 2rem;
            min-width: 60px;
            display: flex;
            justify-content: center;
            margin-top: -2px;
        }
    }

    .nav__link a:hover {
        background-color: var(--background);
        border-radius: 6px;
    }

    .btn__signout {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: var(--secondary-black);
        font-weight: 600;
        transition: all ease-in-out 0.3s;
        border: none;
        background-color: #FFF;

        svg {
            font-size: 2rem;
            min-width: 60px;
            display: flex;
            justify-content: center;
            margin-top: -2px;
        }
        
        &:hover {
            color: #d32f2f;
            background-color: rgba(211, 47, 47, 0.04) !important;
            border-radius: 6px;
        }
    }
}

@media (max-width: 870px) {
    .sidebar {
        width: 85px !important;
    }

    .sidebar__image {
        justify-content: center;
    }
    
    .header__text {
        display: none !important;
    }

    .nav__link a {
        justify-content: center;
    }

    .nav__link a svg {
        min-width: 0 !important;
    }
    
    .nav__link a span,
    .btn__signout span {
        display: none;
    }
}