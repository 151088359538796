.cadastro {
    position: relative;
    left: 250px;
    width: calc(100% - 250px);
    padding-top: 8px;
    padding-bottom: 8px;

    &__top {
        padding: 3rem;
        background-color: var(--primary-green);
        border-radius: 8px 8px 0 0;
    }

    &__container {
        width: 90%;
        max-width: 1280px;
        margin: auto;
        background-color: #FFF;
        border-radius: 0 0 8px 8px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    form {
        padding: 3rem;
    }

    &__titulo {
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 700;
        color: #FFF;

        span {
            display: flex;
            padding-right: 5px;
        }
    }

    .titulo__border {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--secondary-green);
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .form__titulo {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        margin-bottom: 1rem;

        &::after,
        &::before {
            content: "";
            display: block;
            width: 30%;
            height: 1px;
            background-color: var(--secondary-black);
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    .input__group {
        position: relative;
        padding: 1rem;
    }

    .input__field {
        width: 100%;
        background-color: transparent;
        color: var(--primary-black);
        padding: 1rem 4rem 1rem 1rem;
        border: 1px solid var(--secondary-black);
        border-radius: 4px;
        font-size: 1.6rem;
        font-weight: 400;

        &:hover {
            border: 1px solid var(--primary-black);
        }

        &:focus {
            border: 2px solid var(--primary-green);
        }
    }

    .input__field.input__error {
        border-color: red;
    }

    .input__label {
        background-color: #FFF;
        color: var(--secondary-black);
        position: absolute;
        left: 20px;
        top: 5px;
        padding: 0 6px;
        font-size: 1.2rem;
    }

    .input__field:focus+.input__label {
        color: var(--primary-green);
    }

    .input__area {
        width: 100%;
        padding: 1rem;
        border: 1px solid var(--secondary-black);
        border-radius: 4px;
        background: transparent;
        font-size: 1.6rem;
        font-family: 'Roboto';
        color: var(--primary-black);
        font-weight: 400 !important;

        &:hover {
            border: 1px solid var(--primary-black);
        }

        &:focus {
            border: 2px solid var(--primary-green);
        }
    }

    .input__area:focus+.input__label {
        color: var(--primary-green);
    }

    .error__message {
        color: red !important;
        margin-top: 5px !important;
    }

    &__button {
        display: flex;
        justify-content: space-between;
    }
}

._2_g61 {
    padding: 0 !important;
    margin-top: 5rem;
}

._3uApM {
    margin-top: 7rem !important;
}

._hsN1w {
    background-color: var(--primary-green) !important;
    border: none !important;
}

.p-inputnumber {
    width: 100%;
}

.p-inputtext {
    width: 100%;
    background-color: transparent;
    color: var(--primary-black) !important;
    padding: 1rem 4rem 1rem 1rem;
    border: 1px solid var(--secondary-black);
    border-radius: 4px;
    font-size: 1.6rem;
    font-weight: 400 !important;

    &:hover {
        border: 1px solid var(--primary-black);
    }

    &:focus {
        border: 2px solid var(--primary-green);
    }
}

.btn {
    font-size: 1.4rem !important;
}

.drag {
    border-radius: 5px;
    overflow: hidden;
}

.drag .top {
    text-align: center;
}

.drag button {
    outline: 0;
    border: 0;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background-color: #0086fe;
}

.drag .drag__area {
    height: 200px;
    border-radius: 5px;
    border: 2px dashed var(--secondary-black);
    color: var(--primary-black);
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
}

.drag .drag__area .visible {
    font-size: 18px;
}

.drag .select {
    color: var(--primary-green);
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s;
}

.drag .select:hover {
    opacity: 0.6;
}

.drag .container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}

.drag .container .image {
    width: 75px;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
}

.drag .container .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.drag .container .image span {
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}

.drag input,
.drag .drag__area .on-drop,
.drag .drag__area.dragover .visible {
    display: none;
}

.delete {
    z-index: 999;
    color: #0086fe;
}

// EDIT
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.cadastro__top.update {
    background-color: #0069d9 !important;
}

.error__message {
    color: red;
    margin-top: 5px;
}

.uploaded {
    border-radius: 5px;
    border: 1px solid var(--background);
}

.panel__heading {
    padding: 1rem;
    background-color: var(--background);
    border-radius: 5px 5px 0 0;

    h2 {
        font-size: 2rem;
        color: var(--secondary-black);
    }

    p {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;
    }
}

.uploaded__container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 20px;
}

.uploaded__image {
    padding: 1rem 1rem 2.5rem 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;

    img {
        height: 200px;
        width: 300px;
        object-fit: cover;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
}

.btn__trash {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 2px 5px;
    border: 1px solid var(--secondary-black);
    background-color: var(--background);
    border-radius: 2px;
    position: absolute;
    bottom: 5px;
    right: 10px;

    svg {
        color: #a94442;
    }
}

.file__caption {
    margin: 1rem 0;
    padding: 1rem;
    background-color: var(--background);
    border-radius: 4px;
    border: 1px solid var(--secondary-black);
    color: var(--secondary-black);
    cursor: not-allowed;
    display: flex;
    align-items: center;

    svg {
        margin-right: 5px;
    }
}

// DELETE
.cadastro__top.delete {
    background-color: #bb2d3b !important;
}

.input__field.disabled {
    cursor: not-allowed;
    color: var(--secondary-black);

    &:hover {
        border-color: var(--secondary-black);
    }
}

.input__group.disabled {
    cursor: not-allowed !important;

}

.input__area.disabled {
    cursor: not-allowed !important;
    color: var(--secondary-black);

    &:hover {
        border-color: var(--secondary-black);
    }
}

.p-disabled {
    color: var(--secondary-black) !important;
}

@media (max-width: 870px) {
    .cadastro {
        width: calc(100% - 85px);
        left: 85px !important;
    }
}

@media (max-width: 585px) {
    .uploaded__image img {
        width: 180px;
        height: 150px;
    }
}